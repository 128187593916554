import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import feature from '../assets/background.png';

import background from '../assets/background1.png'
import Layout from './Layout';

const HomePage = () => {
  const featuresRef = useRef(null);
  const pricingRef = useRef(null);
  const faqRef = useRef(null);


  const [showHeader, setShowHeader] = useState(false);
  const [openIndex, setOpenIndex] = useState(null);
  
  const navigate = useNavigate();



  const handleStartDesigningClick = () => {
    navigate('/sign-in'); // Redirects to the sign-in page
  };
  const scrollToFeatures = () => {
    featuresRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToPricing = () => {
    pricingRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToFAQ = () => {
    faqRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <Layout>

       {/* Add top padding to avoid content overlapping with the fixed header */}
        {/* Main Banner Section */}
        <div className="flex bg-white items-center justify-center">
  <div className="flex-1 flex flex-col items-center p-10">
    <div className="text-center mb-8">
      
      {/* Add your image here */}
      <img 
        src={background} // Update this to the actual path of the image
        alt="NostAi banner" 
        style={{ width: "1500px", height: "300px" }} 
        className="mb-8 mx-auto " // Adjust width and margin as needed
      />

      <h2 className="text-5xl font-bold text-black mt-4">
        Welcome to NostAi
      </h2>
      <p className="text-lg text-gray-600 mt-4 max-w-2xl mx-auto">
        Create your personalized memory quilt with ease using our AI-powered design tools.
        Let your memories come to life in a beautiful quilt that you can cherish forever.
      </p>
      <button
        onClick={handleStartDesigningClick}
        className="bg-blue-500 text-white py-2 px-6 rounded-lg mt-6"
      >
        Start Designing Your Quilt
      </button>
            {/* Add Know More button with down arrow */}
            <button
        onClick={scrollToFeatures} // This can scroll down to the next section or feature section
        className="flex items-center justify-center text-blue-500 mt-6"
      >
        Know More
        <span className="ml-2 text-2xl">↓</span> {/* Down arrow */}
      </button>

    </div>
  </div>
</div>


        {/* Features Section */}
        <div ref={featuresRef} className="bg-white py-20 px-10" id="features">
          <div className="container mx-auto">
            <div className="text-center mb-12">
              <h2 className="text-5xl font-bold text-black">Meet NostAi</h2>
              <p className="text-xl text-gray-600 mt-4">
                NostAi is your creative companion for designing personalized memory quilts, <br />
                combining ease, creativity, and cherished memories.
              </p>
            </div>

            <div className="flex flex-col md:flex-row items-center">
              <div className="flex-1 mb-10 md:mb-0">
                <div className=" p-6 rounded-lg ">
                  <img 
                    src={feature} 
                    alt="QuiltCraft in action" 
                    className="rounded-lg w-[500px] h-[350px] mx-auto" 
                  />
                </div>
              </div>

              <div className="flex-1 md:pl-10">
                <div className="space-y-6">
                  <div className="flex items-start">
                    <span className="text-2xl text-black mr-4">🧵</span>
                    <div>
                      <h3 className="text-xl font-semibold text-black">Create with NostAi</h3>
                      <p className="text-gray-600">
                        Select your quilt size, upload images of your t-shirts, and choose the type of layout. 
                        NostAi will turn your memories into a beautiful, custom-made quilt.
                      </p>
                    </div>
                  </div>

                  <div className="flex items-start">
                    <span className="text-2xl text-black mr-4">📷</span>
                    <div>
                      <h3 className="text-xl font-semibold text-black">Upload Your Memories</h3>
                      <p className="text-gray-600">
                        Upload images of your favorite t-shirts, and NostAi will use them to build 
                        a unique memory quilt, capturing your cherished moments.
                      </p>
                    </div>
                  </div>

                  <div className="flex items-start">
                    <span className="text-2xl text-black mr-4">🤝</span>
                    <div>
                      <h3 className="text-xl font-semibold text-black">Share and collaborate</h3>
                      <p className="text-gray-600">
                        Collaborate with loved ones, share designs, and work together to create a memory quilt 
                        filled with love and meaning.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Pricing Section */}
        <div ref={pricingRef} className="bg-white py-20 px-10" id="pricing">  
          <div className="container mx-auto text-center">
            <h2 className="text-4xl font-bold text-center text-black mb-12">Explore plans</h2>

            <div className="grid grid-cols-1 md:grid-cols-2  justify-center">
              
              <div className="bg-white shadow-lg rounded-lg p-8 mx-auto">
                <h3 className="text-2xl font-bold text-black mb-4">Free plan</h3>
                <p className="text-lg font-semibold text-black mb-4">$0 / month</p>
                <ul className="space-y-4 text-gray-600">
                  <li className="flex items-center justify-center">
                    <span className="text-green-500 mr-2">✔</span>
                    Upload up to 24 t-shirts
                  </li>
                  <li className="flex items-center justify-center">
                    <span className="text-green-500 mr-2">✔</span>
                    1 AI-generated design suggestion
                  </li>
                  <li className="flex items-center justify-center">
                    <span className="text-green-500 mr-2">✔</span>
                    Experience the basic functionality of the platform
                  </li>
                </ul>
              </div>

              <div className="bg-white shadow-lg rounded-lg p-8 mx-auto">
                <h3 className="text-2xl font-bold text-black mb-4">Paid plan</h3>
                <p className="text-lg font-semibold text-blackmb-4">$10 one-time</p>
                <ul className="space-y-4 text-gray-600">
                  <li className="flex items-center justify-center">
                    <span className="text-green-500 mr-2">✔</span>
                    Upload up to 35 t-shirts
                  </li>
                  <li className="flex items-center justify-center">
                    <span className="text-green-500 mr-2">✔</span>
                    5 AI-generated design suggestions
                  </li>
                  <li className="flex items-center justify-center">
                    <span className="text-green-500 mr-2">✔</span>
                    Explore a wider range of creative quilt layouts
                  </li>
                </ul>
              </div>

            </div>
          </div>
        </div>

        {/* FAQ Section */}
        <div ref={faqRef} className="bg-white py-20 px-10" id="faq">  
          <div className="container mx-auto">
            <h2 className="text-4xl font-bold text-center text-black mb-12">Frequently asked questions</h2>
            
            <div className="space-y-4">
              <div>
                <button onClick={() => toggleFAQ(0)} className="flex justify-between items-center w-full text-left text-lg font-semibold text-black">
                  What is NostAi?
                  <span className="text-2xl">{openIndex === 0 ? '-' : '+'}</span>
                </button>
                {openIndex === 0 && <p className="mt-2 text-gray-600">NostAi is an innovative platform that uses artificial intelligence to help you design and create beautiful memory quilts from your t-shirts.</p>}
              </div>

              <div>
                <button onClick={() => toggleFAQ(1)} className="flex justify-between items-center w-full text-left text-lg font-semibold text-black">
                  How does the AI design process work?
                  <span className="text-2xl">{openIndex === 1 ? '-' : '+'}</span>
                </button>
                {openIndex === 1 && <p className="mt-2 text-gray-600">Simply upload photos of your t-shirts, and our AI will analyze the colors, patterns, and sizes to generate multiple layout suggestions. You can then customize these designs to your liking.</p>}
              </div>

              <div>
                <button onClick={() => toggleFAQ(2)} className="flex justify-between items-center w-full text-left text-lg font-semibold text-black">
                  Do I need quilting experience to use NostAi?
                  <span className="text-2xl">{openIndex === 2 ? '-' : '+'}</span>
                </button>
                {openIndex === 2 && <p className="mt-2 text-gray-600">Not at all! Our AI is designed to make quilt design accessible to everyone, regardless of experience. We also provide tutorials and tips to help you along the way.</p>}
              </div>

              <div>
                <button onClick={() => toggleFAQ(3)} className="flex justify-between items-center w-full text-left text-lg font-semibold text-black">
                  What happens after I finalize my quilt design?
                  <span className="text-2xl">{openIndex === 3 ? '-' : '+'}</span>
                </button>
                {openIndex === 3 && <p className="mt-2 text-gray-600">Once you're happy with your design, you can either download the pattern to make the quilt yourself or use one of our trusted production partners to create the quilt for you.</p>}
              </div>

              <div>
                <button onClick={() => toggleFAQ(4)} className="flex justify-between items-center w-full text-left text-lg font-semibold text-black">
                  Is my data safe with NostAi?
                  <span className="text-2xl">{openIndex === 4 ? '-' : '+'}</span>
                </button>
                {openIndex === 4 && <p className="mt-2 text-gray-600">We take data security seriously. All uploads are encrypted, and we never share your personal information or designs without your explicit permission.</p>}
              </div>

              <div>
                <button onClick={() => toggleFAQ(5)} className="flex justify-between items-center w-full text-left text-lg font-semibold text-black">
                  Can I cancel my subscription at any time?
                  <span className="text-2xl">{openIndex === 5 ? '-' : '+'}</span>
                </button>
                {openIndex === 5 && <p className="mt-2 text-gray-600">Yes, you can cancel your subscription at any time. Your projects will remain accessible based on the terms of your last active subscription period.</p>}
              </div>

              <div>
                <button onClick={() => toggleFAQ(6)} className="flex justify-between items-center w-full text-left text-lg font-semibold text-black">
                  Do you offer refunds?
                  <span className="text-2xl">{openIndex === 6 ? '-' : '+'}</span>
                </button>
                {openIndex === 6 && <p className="mt-2 text-gray-600">We offer a 14-day money-back guarantee on all our paid plans. If you're not satisfied, contact our customer support team for a full refund.</p>}
              </div>

              <div>
                <button onClick={() => toggleFAQ(7)} className="flex justify-between items-center w-full text-left text-lg font-semibold text-black">
                  Can I use NostAi for other fabric projects besides t-shirt quilts?
                  <span className="text-2xl">{openIndex === 7 ? '-' : '+'}</span>
                </button>
                {openIndex === 7 && <p className="mt-2 text-gray-600">While our AI is optimized for t-shirt quilts, many users have successfully used our platform for other memory fabric projects. We're constantly expanding our capabilities, so stay tuned for updates.</p>}
              </div>
            </div>
          </div>
        </div>

        
    
    
    </Layout>
  );
};

export default HomePage;
