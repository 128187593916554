// src/firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';  // Import Firebase Auth
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyDsl4A08FQ_QqvYs_ha7kWVYp72PjC1QjQ",
    authDomain: "memoryquilt-18d90.firebaseapp.com",
    projectId: "memoryquilt-18d90",
    storageBucket: "memoryquilt-18d90.appspot.com",
    messagingSenderId: "874597764969",
    appId: "1:874597764969:web:1983b92184a95b3814b7f8",
    measurementId: "G-2SGCQ71MFE"
  };
  

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const storage = getStorage(app);
// Initialize Firebase Auth
const auth = getAuth(app);

const db = getFirestore(app);

const analytics = getAnalytics(app);
// Export the auth object so it can be used in other parts of your app
export {app, auth,storage,db,analytics };

