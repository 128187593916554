import React, { useState, useEffect } from 'react';

function QuiltEditor({ quiltGrid, gridSize = "2x2", onSave, onCancel }) {
    const [editedGrid, setEditedGrid] = useState([...quiltGrid]); // Editable copy of the quilt grid
    const [originalGrid, setOriginalGrid] = useState([...quiltGrid]); // Original copy for reverting
    const [selectedTiles, setSelectedTiles] = useState([]); // Tracks selected tiles for swapping

    // Parse the gridSize (e.g., "4x4") to get columns and rows
    const [columns, rows] = (gridSize || "2x2").split('x').map(Number); // Fallback to "2x2" if gridSize is missing

    // Update the grid states when quiltGrid changes (for cases where the grid data is dynamically updated)
    useEffect(() => {
        setEditedGrid([...quiltGrid]);
        setOriginalGrid([...quiltGrid]);
    }, [quiltGrid]);

    // Handle tile selection for swapping
    const handleTileSelect = (index) => {
        if (selectedTiles.length < 2) {
            setSelectedTiles([...selectedTiles, index]);
        }

        // Swap tiles if two are selected
        if (selectedTiles.length === 1) {
            const [firstIndex, secondIndex] = [selectedTiles[0], index];
            const newGrid = [...editedGrid];
            [newGrid[firstIndex], newGrid[secondIndex]] = [newGrid[secondIndex], newGrid[firstIndex]];

            setEditedGrid(newGrid);
            setSelectedTiles([]); // Reset selected tiles
        }
    };

    // Save the modified quilt layout
    const handleSave = () => {
        onSave(editedGrid); // Pass the edited grid back to parent for saving
    };

    // Cancel changes and revert to the original layout
    const handleCancel = () => {
        setEditedGrid([...originalGrid]); // Revert back to the original layout
        if (onCancel) onCancel(); // Trigger onCancel if provided
    };

    return (
        <div className="bg-gray-100 p-4 rounded-lg shadow-lg w-full sm:w-[400px] md:w-[500px] flex flex-col items-center border-4 border-gray-300">
            <h2 className="text-xl font-semibold mb-2">Edit Quilt</h2>
            
            {/* Instructional Message */}
            <p className="text-sm text-gray-500 mb-4">Click on tiles to swap positions</p>
            
            <div 
                className="grid gap-2"
                style={{
                    gridTemplateColumns: `repeat(${columns}, 80px)`,
                    gridTemplateRows: `repeat(${rows}, 80px)`,
                }}
            >
                {editedGrid.map((tile, index) => (
                    <div 
                        key={index} 
                        className={`p-1 border ${selectedTiles.includes(index) ? 'border-blue-500' : 'border-gray-300'}`}
                        onClick={() => handleTileSelect(index)}
                        style={{
                            width: '80px',
                            height: '80px',
                        }}
                    >
                        <img src={tile.url} alt={`Tile ${index}`} className="w-full h-full object-cover rounded-md" />
                    </div>
                ))}
            </div>
            
            {/* Action Buttons */}
            <div className="flex space-x-4 mt-4">
                <button 
                    onClick={handleSave} 
                    className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
                >
                    Save 
                </button>
                <button 
                    onClick={handleCancel} 
                    className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600"
                >
                    Cancel
                </button>
            </div>
        </div>
    );
}

export default QuiltEditor;
