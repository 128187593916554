import React, { useState, useRef, useEffect } from 'react'; 
import { storage } from '../firebaseConfig'; // Import Firebase Storage configuration
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject,listAll } from "firebase/storage"; // Import Firebase Storage functions
import loadingGif from '../assets/loading.gif';
import QuiltBuilderWithLayout from './QuiltBuilderWithLayout';
import { analytics,db } from '../firebaseConfig';
import { getAnalytics, logEvent } from "firebase/analytics";
import { collection, addDoc,onSnapshot,query, orderBy,deleteDoc } from 'firebase/firestore';
import QuiltEditor from './QuiltEditor'; // Import QuiltEditor component
import PaymentGateway from './PaymentGateway';
import { doc, setDoc, getDoc } from "firebase/firestore"; // Firestore functions
import { useAuth } from '../components/AuthContext';
import { updateDoc } from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';





function QuiltBuilder() {
    const [uploadedImages, setUploadedImages] = useState([]);
    const [gridSize, setGridSize] = useState(''); // Default no selection for grid size
    const [generated, setGenerated] = useState(false); // Flag to indicate when to generate quilt layout
    const [requiredImages, setRequiredImages] = useState(0); // Number of images required
    const [showWarning, setShowWarning] = useState(''); // State for controlling the warning visibility
    const fileInputRef = useRef(null); // For accessing the file input
    const [quiltImageUrl, setQuiltImageUrl] = useState('');  // New state to hold the quilt image URL
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false); // New state for loading
    const [previousLayoutType, setPreviousLayoutType] = useState(''); // Track the previous layout type
    // Add this state to store the existing session ID
    const [existingSessionId, setExistingSessionId] = useState(null);
   
   
    const quiltDisplayRef = useRef(null); // Reference for quilt display section
    
    const [rating, setRating] = useState(0); // User rating
    const [feedback, setFeedback] = useState(''); // User feedback
    const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);

    const canvasRef = useRef(null); // Canvas reference

    const [isEditing, setIsEditing] = useState(false); // Toggle for edit mode
    const [quiltGrid, setQuiltGrid] = useState([]); // Grid layout for quilt tiles
    const [sessionId, setSessionId] = useState(null);

    const [isEditUnlocked, setIsEditUnlocked] = useState(false);

    const [generateDisabled, setGenerateDisabled] = useState(false);

    const { currentUser } = useAuth(); // Get currentUser from context

    const [isResetModalOpen, setIsResetModalOpen] = useState(false);

    const [isResetLoading, setIsResetLoading] = useState(false);

    const [originalQuiltGrid, setOriginalQuiltGrid] = useState([]); // Original grid layout for revert
    const [isProcessingImages, setIsProcessingImages] = useState(false);
    const [processingError, setProcessingError] = useState(null);
    


    // Function to toggle the reset modal
    const openResetModal = () => setIsResetModalOpen(true);
    const closeResetModal = () => setIsResetModalOpen(false);

    const saveSessionData = async () => {
        if (!currentUser || !currentUser.uid) return;
    
        const sessionRef = doc(db, 'sessions', currentUser.uid);
        const sessionData = {
            uploadedImages, // Save the array of uploaded image URLs
            gridSize,
            quiltImageUrl,
            quiltGrid, // Store quilt layout data if it has been generated
            generated
        };
    
        try {
            await setDoc(sessionRef, sessionData, { merge: true });
            console.log("Session data saved to Firestore.");
        } catch (error) {
            console.error("Error saving session data to Firestore:", error);
        }
    };

    useEffect(() => {
        const restoreSessionData = async () => {
            if (!currentUser || !currentUser.uid) return;
    
            const sessionRef = doc(db, 'sessions', currentUser.uid);
            const sessionSnap = await getDoc(sessionRef);
    
            if (sessionSnap.exists()) {
                const sessionData = sessionSnap.data();
                console.log("Restoring session data from Firestore:", sessionData);
    
                setGridSize(sessionData.gridSize || '');
                setUploadedImages(sessionData.uploadedImages || []);
                setQuiltImageUrl(sessionData.quiltImageUrl || '');
                setQuiltGrid(sessionData.quiltGrid || []);
                setGenerated(sessionData.generated || false);
            }
        };
    
        restoreSessionData();
    }, [currentUser]);

    
    useEffect(() => {
        if (generated) { // Save only after generating the quilt
            saveSessionData();
        }
    }, [uploadedImages, gridSize, quiltGrid]);

    


   



  


    // Function to toggle edit mode
    const handleEditClick = () => {
        setOriginalQuiltGrid([...quiltGrid]);
        setIsEditing(true); // Enter edit mode
    };

    const checkTileUploadStatus = async () => {
        const sessionId = currentUser.uid;
        const sessionRef = doc(db, 'sessions', sessionId);
        const sessionDoc = await getDoc(sessionRef);
    
        if (sessionDoc.exists()) {
            const sessionData = sessionDoc.data();
            const totalTilesUploaded = sessionData?.total_tiles_uploaded || 0;
            return totalTilesUploaded >= requiredImages;
        }
        return false;
    };
    
    const waitForAllTilesToUpload = async () => {
        const maxRetries = 10;
        let retries = 0;
        while (retries < maxRetries) {
            const allTilesUploaded = await checkTileUploadStatus();
            if (allTilesUploaded) return true;
            await new Promise((resolve) => setTimeout(resolve, 6000)); 
            retries += 1;
        }
        return false;
    };
    

  



// Restore saved state from localStorage when component mounts
useEffect(() => {
    const savedState = JSON.parse(sessionStorage.getItem("quiltState"));
    if (savedState) {
        console.log("Restoring state from sessionStorage:", savedState);
        setGridSize(savedState.gridSize || '')
        setUploadedImages(savedState.uploadedImages || []);
        setQuiltImageUrl(savedState.quiltImageUrl || '');
        setQuiltGrid(savedState.quiltGrid || []);
        setIsEditUnlocked(savedState.isEditUnlocked || false);
        setGenerateDisabled(savedState.generateDisabled || false); // Restore generation status

        // Disable Generate button if previously generated
   

        console.log("isEditUnlocked after restoration:", savedState.isEditUnlocked);
        sessionStorage.removeItem("quiltState"); // Optional: clear after using
    }
}, []);













    

    useEffect(() => {
        console.log("useEffect triggered: quiltGrid =", quiltGrid);
        if (quiltGrid.length > 0) {
            drawOnCanvas(quiltGrid);
        }
    }, [quiltGrid]);
    
    const handleSaveChanges = (updatedGrid) => {
        console.log("Saving changes with updatedGrid:", updatedGrid);
        setQuiltGrid(updatedGrid);
        setIsEditing(false);
    
        setTimeout(() => {
            drawOnCanvas(updatedGrid);
        }, 0);
    };

    const handleCancelEdit = () => {
        setQuiltGrid([...originalQuiltGrid]); // Revert to original layout
        setIsEditing(false); // Exit edit mode without saving
    };

    useEffect(() => {
        if (currentUser && !sessionId) {
            setSessionId(currentUser.uid);
            setExistingSessionId(currentUser.uid); // Synchronize with existingSessionId
        }
    }, [currentUser, sessionId]);
    
    
    

    



    

    

    
    // Scroll to quilt display section after generation
// Scroll to quilt display section after generation with offset
// useEffect to scroll down after quiltImageUrl is set and loading is false
useEffect(() => {
    if (quiltImageUrl && !loading) {
        // Adding a small delay to ensure the quilt is rendered before scrolling
        setTimeout(() => {
            scrollToQuilt();
        }, 100); // Short delay to ensure rendering is complete
    }
}, [quiltImageUrl, loading]);

// Scroll function
const scrollToQuilt = () => {
    if (quiltDisplayRef.current) {
        const offset = -50; // Adjust offset if needed
        const topPosition = quiltDisplayRef.current.getBoundingClientRect().top + window.scrollY + offset;
        window.scrollTo({ top: topPosition, behavior: 'smooth' });
    }
};


    const drawOnCanvas = (grid) => { 
        const canvas = canvasRef.current;
        if (!canvas) {
            console.error("Canvas element not found.");
            return;
        }
    
        // Ensure `grid` is an array before proceeding
        if (!Array.isArray(grid)) {
            console.error("Invalid grid format. Expected an array but received:", grid);
            return;
        }
    
        const ctx = canvas.getContext("2d");
        const tileSize = 100; // Adjust the tile size as needed
    
        // Determine rows and columns based on grid size
        const [columns, rows] = gridSize.split('x').map(Number); // Parse columns and rows correctly

    
        // Set canvas dimensions based on the grid size, with extra space for the border and text
        const borderSize = 10; // Thickness of the border
        const textHeight = 30; // Space for the text
        canvas.width = columns * tileSize + 2 * borderSize;
        canvas.height = rows * tileSize + 2 * borderSize + textHeight;
    
        ctx.clearRect(0, 0, canvas.width, canvas.height);
    
        // Draw the white border around the quilt
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, canvas.width, canvas.height); // White background for the border
    
        // Draw the quilt tiles inside the border
        grid.forEach((tile, index) => {
            if (!tile.url) {
                console.error("Invalid tile format, missing 'url':", tile);
                return;
            }
    
            const img = new Image();
            img.crossOrigin = "anonymous";
            img.src = tile.url;
    
            img.onload = () => {
                const x = (index % columns) * tileSize + borderSize;
                const y = Math.floor(index / columns) * tileSize + borderSize;
                ctx.drawImage(img, x, y, tileSize, tileSize);
            };
    
            img.onerror = (error) => {
                console.error("Failed to load image for canvas rendering:", error);
            };
        });
    
        // Draw the text at the bottom center of the quilt
        ctx.fillStyle = "black"; // Text color
        ctx.font = "bold 16px Arial";
        ctx.textAlign = "center";
        ctx.fillText("Generated with Nostai", canvas.width / 2, canvas.height - textHeight / 2);
    };
    
    
    
    
    



  
    
    




    useEffect(() => {
        if (loading) {
            scrollToQuilt();  // Scroll down when loading starts
        }
    }, [loading]);



    const handleDownloadClick = () => {
        const canvas = canvasRef.current;
        const imageUrl = canvas.toDataURL("image/jpeg");
    
        const link = document.createElement('a');
        link.href = imageUrl;
        link.download = 'generated_quilt_with_label.jpg';
        link.click();
    };
    


    // Function to calculate required images based on grid size
    const calculateRequiredImages = (gridSize) => {
        switch (gridSize) {
            case "2x2":
                return 4;
            case "2x3":
                return 6;
                
            case "4x4":
                return 16;
            case "4x5":
                return 20;
            case "4x6":
                return 24;
            case "4x7":
                return 28;
            case "5x5":
                return 25;
            case "5x6":
                return 30;
            case "5x7":
                return 35;
            case "6x6":
                return 36;
            case "6x7":
                return 42;
            case "7x7":
                return 49;
            default:
                return 0; // Fallback for cases where no grid is selected
        }
    };

    const handleImageUpload = async (event) => {
        setIsProcessingImages(true); // Start processing state
        setProcessingError(null); // Clear previous errors
        const files = Array.from(event.target.files);
    
        if (uploadedImages.length + files.length > requiredImages) {
            setShowWarning(`You can only upload ${requiredImages} T-shirts for the ${gridSize} grid.`);
            setIsProcessingImages(false);
            return;
        }
    
        if (!sessionId) {
            setSessionId(currentUser.uid); // Generate sessionId if not set
        }
    
        const newUploadedImages = await Promise.all(
            files.map(async (file) => {
                try {
                    // Upload to Firebase and get URL
                    const uniqueFileName = `${uuidv4()}_${file.name}`;
                    const storageRef = ref(storage, `quilt_designs/${sessionId}/${uniqueFileName}`);
                    await uploadBytes(storageRef, file);
                    const url = await getDownloadURL(storageRef);
    
                    // Immediately add the image to the displayed list with `processed: false`
                    const uploadedFile = { url, path: storageRef.fullPath, processed: false };
                    setUploadedImages((prevImages) => [...prevImages, uploadedFile]);
    
                    // Send the image to the backend for processing
                    const formData = new FormData();
                    formData.append('session_id', sessionId);
                    formData.append('image', file);
    
                    const response = await fetch('https://my-fastapi-app-289455571528.us-central1.run.app/process_image', {
                        method: 'POST',
                        body: formData,
                    });
    
                    const result = await response.json();
                    if (response.ok) {
                        console.log(`Processed image ${file.name} successfully.`);
                        // Update the uploaded image's `processed` status in state
                        setUploadedImages((prevImages) =>
                            prevImages.map((img) =>
                                img.url === url ? { ...img, processed: true } : img
                            )
                        );
                    } else {
                        console.error(`Failed to process image ${file.name}:`, result.error);
                        setProcessingError(`Failed to process image ${file.name}`);
                    }
                    return uploadedFile;
                } catch (error) {
                    console.error(`Error during processing for ${file.name}:`, error);
                    setProcessingError(`Error processing image ${file.name}`);
                    return null;
                }
            })
        );
    
        // Check if all images in the updated state have been processed
        const checkAllProcessed = () => {
            setUploadedImages((prevImages) => {
                const allProcessed = prevImages.every((img) => img.processed);
                setIsProcessingImages(!allProcessed); // Set to false if all are processed
                return prevImages; // Return the current state to avoid unnecessary updates
            });
        };
    
        // Delay checkAllProcessed slightly to ensure all updates are applied
        setTimeout(checkAllProcessed, 100);
    };
    
    
    
    
    // Handle grid size change
    const handleGridSizeChange = (event) => {
        if (generated) {
            // Prevent grid size change after quilt generation and prompt user to reset
            setShowWarning('Grid size cannot be changed after quilt generation. Please reset to change the grid size.');
            return; // Prevent further execution if quilt is already generated
        }

        const selectedGridSize = event.target.value;
        setGridSize(selectedGridSize);
        const calculatedImages = calculateRequiredImages(selectedGridSize);
        setRequiredImages(calculatedImages);

        logEvent(analytics, 'select_grid_size', { grid_size: selectedGridSize });

        // Allow user to upload more images if new grid size requires more
        if (uploadedImages.length < calculatedImages) {
            setShowWarning(`Please upload ${calculatedImages - uploadedImages.length} more T-shirts for the ${selectedGridSize} grid.`);
        } else if (uploadedImages.length === calculatedImages) {
            setShowWarning(''); // No warning if the exact number of images is already uploaded
        } else {
            setShowWarning(`You have uploaded more than required. You can only upload ${calculatedImages} T-shirts for the ${selectedGridSize} grid.`);
        }

        setGenerated(false); // Reset the generation flag if the grid size is changed
    };



    // Handle image upload click
    const handleUploadClick = (e) => {
        if (!gridSize ) {
            e.preventDefault(); // Prevent the file picker from opening
            setShowWarning('Please select grid size before uploading images.');
        } else if (uploadedImages.length >= requiredImages) {
            e.preventDefault(); // Prevent file picker if user has uploaded the maximum number of images
            setShowWarning(`You can only upload ${requiredImages} T-shirts for the ${gridSize} grid.`);
        } else {
            setShowWarning(''); // Hide warning if everything is selected
        }
    };

    const handleGenerateClick = async () => {
        if (uploadedImages.length < requiredImages) {
            setShowWarning(`Please upload exactly ${requiredImages} T-shirts for the ${gridSize} grid before generating.`);
        } else if (!gridSize) {
            setShowWarning('Please select grid size before generating.');
        } else if (isProcessingImages || processingError) {
            setShowWarning(processingError ? processingError : 'Processing images, please wait...');
        } else {
            setGenerated(true);
            setLoading(true); // Start loading state
            setShowWarning('');
            setGenerateDisabled(true); // Disable Generate button
        
            try {
                // Wait for all tiles to be uploaded before generating the quilt
                const allTilesUploaded = await waitForAllTilesToUpload();
                if (!allTilesUploaded) {
                    setShowWarning("Not all images have been processed. Please try again.");
                    setLoading(false);
                    //setGenerateDisabled(false);
                    return;
                }
        
                const payload = {
                    session_id: currentUser.uid,  // Use the userId as sessionId
                    grid_size: gridSize,
                    layout_type: "Random",
                };
        
                const apiRequest = fetch('https://my-fastapi-app-289455571528.us-central1.run.app/generate_quilt', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                }).then(response => response.json());
        
                const delay = new Promise(resolve => setTimeout(resolve, 3000));
                const [result] = await Promise.all([apiRequest, delay]);
        
                if (result && result.quilt_image_url) {
                    console.log('Quilt generated successfully:', result);
                    setQuiltImageUrl(result.quilt_image_url);
                    setQuiltGrid(result.tile_urls);
                    //await saveSessionData();
                } else {
                    const errorMessage = result.error || 'An unexpected error occurred while generating the quilt.';
                    console.error('Failed to generate quilt:', errorMessage);
                    setShowWarning(`Error generating quilt: ${errorMessage}`);
                }
            } catch (error) {
                console.error('Error during image upload or API request:', error);
                setShowWarning(`Error: ${error.message}`);
            } finally {
                setLoading(false); // End loading state after at least 3 seconds
            }
        }
    };
    
    
    

 
// Handle image deletion from Firebase Storage and state
const handleDeleteImage = async (index) => {
    const imageToDelete = uploadedImages[index];

    // Check if the image is only locally stored (no path property)
    if (!imageToDelete || !imageToDelete.path) {
        console.warn(`Image "${imageToDelete.name}" is only stored locally. Removing locally only.`);
        
        // Remove the image from local state without Firebase deletion
        setUploadedImages((prevImages) => prevImages.filter((_, i) => i !== index));
        return;
    }

    // If path exists, proceed to delete from Firebase
    try {
        const storageRef = ref(storage, imageToDelete.path);
        await deleteObject(storageRef);
        console.log(`Successfully deleted "${imageToDelete.name}" from Firebase Storage.`);

        // Remove from local state after successful deletion
        setUploadedImages((prevImages) => prevImages.filter((_, i) => i !== index));
    } catch (error) {
        console.error(`Failed to delete image "${imageToDelete.name}" from Firebase Storage:`, error);
    }
};


    // Handle clicking the disabled generate button (fake button overlay)
    const handleDisabledGenerateClick = () => {
        if (!gridSize) {
            setShowWarning('Please select grid size before generating.');
        } else if (uploadedImages.length < requiredImages) {
            setShowWarning(`Please upload exactly ${requiredImages} T-shirts for the ${gridSize} grid before generating.`);
        }
    };

    const handleFullScreenClick = () => {
        setIsModalOpen(true); // Open the modal
    };

    const closeModal = () => {
        setIsModalOpen(false); // Close the modal
    };

    const handleReset = async () => {
        const isConfirmed = window.confirm("Are you sure you want to reset all changes? This action cannot be undone.");
        
        if (isConfirmed) {
            try {
                // Clear uploaded images from Firebase Storage
                for (const image of uploadedImages) {
                    if (image.path) {
                        const storageRef = ref(storage, image.path);
                        await deleteObject(storageRef);
                        console.log(`Deleted image from Firebase Storage: ${image.path}`);
                    }
                }
    
                // Clear session data from Firestore
                if (sessionId) {
                    const sessionRef = doc(db, 'sessions', sessionId);
                    await deleteDoc(sessionRef);
                    console.log(`Deleted session data from Firestore: ${sessionId}`);
                }
    
                // Reset local state
                setUploadedImages([]); // Clear uploaded images
                setGridSize(''); // Reset grid size
                setGenerated(false); // Reset the generated flag
                setRequiredImages(0); // Reset required images
                setShowWarning(''); // Clear any warnings
                setQuiltImageUrl(''); // Clear the quilt image URL
                setPreviousLayoutType(''); // Reset previous layout type
                setGenerateDisabled(false); // Re-enable Generate button
                setIsEditUnlocked(false); // Reset edit unlock state
                setQuiltGrid([]); // Clear quilt grid data
                setSessionId(null); // Clear session ID if relevant
    
                // Clear session storage to remove any saved state
                sessionStorage.removeItem("quiltState");
    
                console.log("Reset everything to default.");
            } catch (error) {
                console.error("Error during reset:", error);
            }
        } else {
            console.log("Reset action was canceled.");
        }
    };


    const confirmReset = async () => {
        setIsResetLoading(true);
        try {
            // Clear all images under the session folder in Firebase Storage
            if (sessionId) {
                const storageRef = ref(storage, `quilt_designs/${sessionId}/`);
                const listResult = await listAll(storageRef); // List all items under the session folder
                const deletePromises = listResult.items.map((itemRef) => deleteObject(itemRef));
                await Promise.all(deletePromises);
                console.log(`Deleted all images from Firebase Storage under quilt_designs/${sessionId}/`);
            }
    
            // Clear session data from Firestore if needed
            if (sessionId) {
                const sessionRef = doc(db, 'sessions', sessionId);
                await deleteDoc(sessionRef);
                console.log(`Deleted session data from Firestore: ${sessionId}`);
            }
    
            // Reset local state
            setUploadedImages([]); // Clear uploaded images
            setGridSize(''); // Reset grid size
            setGenerated(false); // Reset the generated flag
            setRequiredImages(0); // Reset required images
            setShowWarning(''); // Clear any warnings
            setQuiltImageUrl(''); // Clear the quilt image URL
            setPreviousLayoutType(''); // Reset previous layout type
            setGenerateDisabled(false); // Re-enable Generate button
            setIsEditUnlocked(false); // Reset edit unlock state
            setQuiltGrid([]); // Clear quilt grid data
            setSessionId(null); // Clear session ID if relevant
            setFeedbackSubmitted(false); // Reset feedback submission state
            setRating(0); // Reset rating if needed
    
            // Clear session storage to remove any saved state
            sessionStorage.removeItem("quiltState");
    
            console.log("Reset everything to default.");
        } catch (error) {
            console.error("Error during reset:", error);
        }
        setIsResetLoading(false);
        closeResetModal(); // Close the modal
    };
    
    
    
    

    const handleSubmitFeedback = async () => {
        if (!currentUser || !currentUser.uid || !currentUser.email) {
            console.error("User not logged in, user ID, or email not available.");
            return;
        }
    
        try {
            const feedbackData = {
                userId: currentUser.uid, // Include the user ID
                email: currentUser.email, // Include the user's email
                rating: rating,
                timestamp: new Date(),
            };
    
            await addDoc(collection(db, "user_feedback"), feedbackData); // Save feedback to Firestore
            setFeedbackSubmitted(true);
        } catch (error) {
            console.error("Error submitting feedback:", error);
        }
    };
    


    

    return (
        <QuiltBuilderWithLayout handleReset={openResetModal}>
            <div className="flex flex-col w-full min-h-screen">
    
                {/* Main Content Area */}
                <div className="flex flex-col items-center w-full overflow-y-auto p-8">
                    {/* Top bar containing centered title and reset button */}
                    <div className="flex flex-col items-center w-full overflow-auto p-8 relative">
                        <div className="text-3xl font-semibold text-gray-800">
                            Craft your memory quilt.
                        </div>
                    </div>
    
                    {/* Reset Confirmation Modal */}
                    {isResetModalOpen && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
                                <h2 className="text-lg font-semibold">Start New Project?</h2>
                                <p className="mt-2 text-gray-600">
                                    Your current design will be lost. Please make sure to download your design before starting a new project.
                                </p>
                                <div className="mt-4 flex justify-end space-x-4">
                                    <button
                                        className="px-4 py-2 bg-gray-200 rounded-md text-gray-700 hover:bg-gray-300"
                                        onClick={closeResetModal}
                                    >
                                        Cancel
                                    </button>
                                    <button
        className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 flex items-center justify-center"
        onClick={confirmReset}
        disabled={isResetLoading} // Disable button when loading
    >
        {isResetLoading ? (
            <>
                <span className="spinner"></span> {/* Spinner element */}
                Loading...
            </>
        ) : (
            "Continue"
        )}
    </button>
                                </div>
                            </div>
                        </div>
                    )}
    
                    {/* Grid Size Section */}
<div className="flex justify-center w-full mb-8">
    <div className="bg-white w-full sm:w-[400px] p-4 rounded-lg shadow-lg">
        <div className="bg-gray-100 p-4 rounded-lg">
            <div className="flex flex-col items-center">
                <label className="text-sm font-bold mb-1">Grid Size</label>
                <select
                    value={gridSize}
                    onChange={handleGridSizeChange}
                    className="border rounded-lg p-2 text-gray-800 bg-white shadow-md"
                >
                    <option value="" disabled>Select</option>
                    <option value="2x2">2x2 (4 T-shirts)</option>
                    <option value="2x3">2x3 (6 T-shirts)</option>
                    <option value="4x4">4x4 (16 T-shirts)</option>
                    <option value="4x5">4x5 (20 T-shirts)</option>
                    <option value="4x6">4x6 (24 T-shirts)</option>
                    <option value="4x7">4x7 (28 T-shirts)</option>
                    <option value="5x5">5x5 (25 T-shirts)</option>
                    <option value="5x6">5x6 (30 T-shirts)</option>
                    <option value="5x7">5x7 (35 T-shirts)</option>
                    <option value="6x6">6x6 (36 T-shirts)</option>
                    <option value="6x7">6x7 (42 T-shirts)</option>
                    <option value="7x7">7x7 (49 T-shirts)</option>
                </select>
            </div>
        </div>
    </div>
</div>

    
                    {/* Warning message */}
                    {showWarning && (
                        <div className="mb-4 text-red-600">
                            {showWarning}
                        </div>
                    )}
    
                    {/* Notify user to upload images */}
                    {!showWarning && gridSize && uploadedImages.length < requiredImages && (
                        <div className="mb-4 text-blue-600">
                            Please upload {requiredImages - uploadedImages.length} more T-shirts for the {gridSize} grid.
                        </div>
                    )}
    
                    {/* Image Upload Container and Generate Button */}
                    <div className="flex flex-col items-center bg-white border border-gray-300 p-6 rounded-lg shadow-lg w-full sm:w-[700px]">
                        {/* Upload Icon and file input */}
{!generated && !loading && (
    <label className="flex flex-col items-center justify-center cursor-pointer">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-12 w-12 text-gray-500"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M3 10h4M3 6h4m4 0h6M3 14h4m4 0h6m0 0v5a2 2 0 01-2 2H8a2 2 0 01-2-2v-5m0-4h4m4 0h4"
            />
        </svg>
        <span className="mt-2 text-sm text-gray-600">
            Upload T-shirts ({uploadedImages.length}/{requiredImages})
        </span>
        <input
            type="file"
            multiple
            ref={fileInputRef}
            onClick={handleUploadClick}
            onChange={handleImageUpload}
            className="hidden"
        />
    </label>
)}

    
                        <div className="w-full border-t border-gray-300 my-4"></div>
    
                        <div className="mt-4 w-full h-32 overflow-y-auto grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 gap-4">
                            {uploadedImages.map((image, index) => (
                                <div key={index} className="relative w-24 h-24 p-1">
                                    <img
                                        src={image.url}
                                        alt={`Uploaded ${index}`}
                                        className="w-full h-full object-cover rounded-lg"
                                    />
                                    {!image.processed && (
                                        <div className="absolute inset-0 flex items-center justify-center bg-gray-200 bg-opacity-50">
                                            <span className="text-blue-500">Processing...</span>
                                        </div>
                                    )}
                                    {!generated && !loading && (
                                        <button
                                            className="absolute top-1 right-1 bg-red-500 text-white text-xs w-6 h-6 flex items-center justify-center rounded-full"
                                            onClick={() => handleDeleteImage(index)}
                                        >
                                            X
                                        </button>
                                    )}
                                </div>
                            ))}
                        </div>
    
                        {/* Generate Button next to image upload container */}
                        <div className="relative flex justify-center mt-4">
                            <button
                                onClick={handleGenerateClick}
                                className={`bg-blue-500 text-white px-6 py-3 rounded-lg ${
                                    isProcessingImages || generateDisabled || loading || uploadedImages.length < requiredImages || !gridSize  || uploadedImages.some(img => !img.processed)
                                        ? 'opacity-50 cursor-not-allowed'
                                        : 'hover:bg-blue-600'
                                }`}
                                disabled={
                                    isProcessingImages || generateDisabled || loading || uploadedImages.length < requiredImages || !gridSize || uploadedImages.some(img => !img.processed)
                                }
                            >
                                {isProcessingImages ? 'Processing...' : loading ? 'Generating...' : 'Generate'}
                            </button>
                            
                            {processingError && (
                                <div className="text-red-600 mt-2">{processingError}</div>
                            )}
    
                            {/* Overlay only if the button is disabled */}
                            {(uploadedImages.length < requiredImages || !gridSize) && (
                                <div
                                    className="absolute inset-0 flex items-center justify-center cursor-pointer"
                                    onClick={handleDisabledGenerateClick}
                                    style={{ zIndex: 10, backgroundColor: 'rgba(0, 0, 0, 0)' }} // Transparent overlay
                                ></div>
                            )}
                        </div>
                    </div>
    
                    <div ref={quiltDisplayRef} className="flex flex-col items-center justify-center w-full mt-8">
                        <div className="bg-gray-100 p-4 rounded-lg shadow-lg w-full sm:w-[500px] md:w-[600px] flex flex-col items-center border-4 border-gray-300">
                            
                            {/* Main Content */}
                            {loading ? (
                                <img src={loadingGif} alt="Loading..." className="w-16 h-16" />
                            ) : quiltImageUrl ? (
                                <>
                                    {/* Toggle between Quilt Display and QuiltEditor */}
                                    {isEditing ? (
                                        <QuiltEditor quiltGrid={quiltGrid} gridSize={gridSize} onSave={handleSaveChanges} onCancel={handleCancelEdit} />
                                    ) : (
                                        <>
                                            {/* Canvas Element */}
                                            <canvas ref={canvasRef} className="mb-4"></canvas>
    
                                            {/* Action Buttons */}
                                            <div className="flex space-x-4 mt-4">
                                                <button
                                                    onClick={handleEditClick}
                                                    className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
                                                >
                                                    Edit Quilt
                                                </button>
                                                <button
                                                    onClick={handleDownloadClick}
                                                    className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
                                                >
                                                    Download Design
                                                </button>
                                                <button
                                                    onClick={openResetModal}
                                                    className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600"
                                                >
                                                    New Project
                                                </button>
                                            </div>
                                        </>
                                    )}
                                </>
                            ) : (
                                <span className="text-gray-500">Generated quilt will appear here after generation</span>
                            )}
    
                            {/* Feedback Section */}
                            {quiltImageUrl && !feedbackSubmitted && (
                                <div className="flex flex-col items-center mt-8 mb-4">
                                    <div className="flex space-x-2 mb-2">
                                        {[1, 2, 3, 4, 5].map((star) => (
                                            <button
                                                key={star}
                                                onClick={() => setRating(star)}
                                                className={`text-2xl ${star <= rating ? 'text-yellow-400' : 'text-gray-300'}`}
                                            >
                                                ★
                                            </button>
                                        ))}
                                    </div>
                                    <button
                                        onClick={handleSubmitFeedback}
                                        className="bg-blue-500 text-white px-4 py-1 rounded-lg hover:bg-blue-600 mt-2"
                                    >
                                        Submit Feedback
                                    </button>
                                </div>
                            )}
                            {feedbackSubmitted && (
                                <div className="text-green-500 mt-4">Thank you for your feedback!</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </QuiltBuilderWithLayout>
    );
}    
export default QuiltBuilder;
