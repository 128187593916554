import React from 'react'; 
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage';
import QuiltBuilder from './components/QuiltBuilder';
import AboutUs from './components/AboutUs'; // Import the AboutUs component
import TermsOfService from './components/TermsOfService'; // Import TermsOfService
import PrivacyPolicy from './components/PrivacyPolicy'; // Import PrivacyPolicy
import Contact from './components/Contact';
import SignUp from './components/SignUp';
import { AuthProvider } from './components/AuthContext'; // Import the AuthProvider

function App() {
  return (
    <AuthProvider> {/* Wrap Routes with AuthProvider */}
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/QuiltBuilder" element={<QuiltBuilder />} />
          <Route path="/sign-in" element={<SignUp />} />
          <Route path="/about" element={<AboutUs />} /> 
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
