import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from '../assets/logo_main.jpg';

const Layout = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation(); // Get the current route

  // Scroll to section by id
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Handle navigation or scrolling
  const handleNavClick = (sectionId) => {
    if (location.pathname === '/') {
      // If on the homepage, scroll to section
      scrollToSection(sectionId);
    } else {
      // If not on the homepage, navigate to the homepage and scroll
      navigate('/');
      setTimeout(() => scrollToSection(sectionId), 100); // Delay to ensure homepage loads
    }
  };

  const handleStartDesigningClick = () => {
    navigate('/sign-in'); // Navigate to the sign-in page
  };

  return (
    <div className="min-h-screen bg-white">
      {/* Header */}
      <header className="fixed top-0 w-full bg-[#e0e0e0] py-4 shadow-md z-50">
        <div className="container mx-auto flex justify-between items-center px-10">
          <div className="flex items-center">
            <img src={logo} alt="Logo" className="w-8 h-8 mr-2" />
            <h1 onClick={() => navigate('/')} className="text-2xl font-bold text-slate-800 cursor-pointer">NostAi</h1>
          </div>
          <nav className="space-x-8">
            <button onClick={() => handleNavClick('features')} className="text-black">
              Features
            </button>
            <button onClick={() => handleNavClick('pricing')} className="text-black">
              Pricing
            </button>
            <button onClick={() => handleNavClick('faq')} className="text-black">
              FAQ
            </button>
          </nav>
        </div>
      </header>

      <div className="pt-20">
        {children} {/* Render the page content */}
      </div>

      {/* Footer */}
      <footer className="bg-black text-white py-10">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row justify-between">
            <div className="text-sm">
              <p>© 2024 All Rights Reserved | beautgroup</p>
            </div>

            {/* Right Section: Company links only, displayed horizontally */}
            <div className="flex justify-start space-x-8 mt-6 md:mt-0">
              <ul className="flex space-x-6">
                <li>
                  <a href="/about" className="text-white-400 hover:text-white">
                    About Us
                  </a>
                </li>
                <li>
                  <a href="/terms-of-service" className="text-white-400 hover:text-white">
                    Terms of Service
                  </a>
                </li>
                <li>
                  <a href="/privacy-policy" className="text-white-400 hover:text-white">
                    Privacy policy
                  </a>
                </li>
                <li>
                  <a href="/Contact" className="text-white-400 hover:text-white">
                    Contact
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Layout;
