// src/components/SignUp.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleAuthProvider, signInWithPopup, createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebaseConfig'; // Adjust the path if needed
import { FcGoogle } from 'react-icons/fc'; // Google icon from react-icons
import Layout from './Layout';
const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSigningIn, setIsSigningIn] = useState(false); // Toggle between sign-in and sign-up
  const [errorMessage, setErrorMessage] = useState(''); // State to hold error messages

  const navigate = useNavigate();
  
  

  // Google Sign-In Function
  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      console.log('User signed in with Google');
      navigate('/QuiltBuilder'); // Redirect to Chatbox after login
    } catch (error) {
      console.error('Error during Google Sign-In:', error);
      setErrorMessage(error.message); // Set error message
    }
  };

  // Email Sign-Up Function
  const handleEmailSignUp = async () => {
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      console.log('User signed up with email');
      navigate('/QuiltBuilder'); // Redirect after sign-up
    } catch (error) {
      console.error('Error during email sign-up:', error);
      setErrorMessage(error.message); // Set error message
    }
  };

  // Email Sign-In Function
  const handleEmailSignIn = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      console.log('User signed in with email');
      navigate('/QuiltBuilder'); // Redirect after sign-in
    } catch (error) {
      console.error('Error during email sign-in:', error);
      setErrorMessage(error.message); // Set error message
    }
  };

  return (
    <Layout>
    <div className="flex items-center justify-center min-h-screen bg-white-100">
      <div className="bg-white shadow-md rounded-lg p-8 w-[400px] mb-6">
        <p className="text-lg text-[#40302e] mb-6 text-center">
          Start designing your quilt.
        </p>

        {/* Display Error Message */}
        {errorMessage && <p className="text-red-500 text-center mb-4">{errorMessage}</p>}

        
        {/* Continue with Google Button */}
        <button
          className="border border-gray-300 text-gray-700 font-medium rounded-lg flex items-center justify-center w-full py-2 mb-4 bg-white hover:bg-gray-100"
          onClick={signInWithGoogle}>
          <FcGoogle className="text-2xl mr-2" /> Continue with Google
        </button>

        {/* Divider */}
        <p className="text-sm text-gray-500 mb-4 text-center">OR</p>

        {/* Email Input */}
        <input
          type="email"
          placeholder="email"
          className="border border-gray-300 rounded-lg px-4 py-3 w-full mb-4"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        {/* Password Input */}
        <input
          type="password"
          placeholder="Password"
          className="border border-gray-300 rounded-lg px-4 py-3 w-full mb-4"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        {/* Conditional rendering for Sign-In or Sign-Up */}
        {isSigningIn ? (
          <button className="bg-blue-500 text-white font-medium text-center rounded-lg w-full py-3 mb-4" onClick={handleEmailSignIn}>
            Sign In with email
          </button>
        ) : (
          <button className="bg-blue-500 text-white font-medium text-center rounded-lg w-full py-3 mb-4" onClick={handleEmailSignUp}>
            Sign Up with email
          </button>
        )}

        {/* Toggle between Sign-In and Sign-Up */}
        <p className="text-sm text-gray-500 mt-4 text-center">
          {isSigningIn ? "Don't have an account?" : "Already have an account?"}{' '}
          <span
            onClick={() => setIsSigningIn(!isSigningIn)}
            className="text-[#a07545] underline cursor-pointer"
          >
            {isSigningIn ? 'Sign Up' : 'Sign In'}
          </span>
        </p>

        {/* Terms and Conditions */}
        <p className="text-xs text-gray-500 mt-4 text-center">
          By continuing, you agree to beaut group{' '}
          <a href="/privacy-policy" className="text-[#a07545] underline">
            Privacy Policy
          </a>.
        </p>
      </div>
    </div>
    </Layout>
  );
};

export default SignUp;