import React from 'react';
import Layout from './Layout';
const Contact = () => {
  return (
    <Layout>
    <div className="container mx-auto py-10 px-5">
      <h1 className="text-4xl font-bold text-[#40302e] mb-8">Contact Us</h1>
      
      <p className="text-gray-600 mb-4">
        If you can’t find the answers you’re looking for in our FAQ, feel free to reach out to us:
      </p>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold text-[#40302e] mb-4">Email</h2>
        <p className="text-gray-600 mb-4">
          Email: [Insert Email Address] <br />
          Our support team will respond to your inquiry within 24-48 hours.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold text-[#40302e] mb-4">Live Chat</h2>
        <p className="text-gray-600 mb-4">
          Available from [Insert Hours] <br />
          Click the chat icon in the bottom right corner of the website to speak with a representative.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold text-[#40302e] mb-4">Phone Support</h2>
        <p className="text-gray-600 mb-4">
          Phone Support: [Insert Phone Number] <br />
          Available from [Insert Hours].
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold text-[#40302e] mb-4">Mailing Address</h2>
        <p className="text-gray-600 mb-4">
          [Insert Business Address] <br />
          If you prefer to reach us by mail, please allow additional time for a response.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold text-[#40302e] mb-4">Support Hours</h2>
        <p className="text-gray-600 mb-4">
          Our customer support team is available during the following hours:
        </p>
        <ul className="list-disc list-inside text-gray-600">
          <li>Monday – Friday: 9 AM – 6 PM [Your Time Zone]</li>
          <li>Saturday: 10 AM – 4 PM [Your Time Zone]</li>
        </ul>
      </div>
    </div>
    </Layout>
  );
};

export default Contact;
